<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex align-center justify-space-between">
        <h3></h3>
        <v-btn
          color="secondary"
          class="text--primary text-none elevation-0"
          @click="openConferenceAdminUserModal()"
          dark>
          Nuevo
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <v-text-field
          v-model="params.filterName"
          label="Nombres/Apellidos"
          v-on:keyup.enter="search"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="col-4">
        <v-text-field
          v-model="params.filterEmail"
          label="Email"
          v-on:keyup.enter="search"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="col-4">
        <v-select
          v-model="params.filterCompany"
          :items="companies"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Empresa"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-end">
        <v-btn
          color="primary"
          @click="search"
          class="white--text text-none elevation-0 mr-2">
          <v-icon
            left
            dark>
            fa-search
          </v-icon>
          Buscar
        </v-btn>
        <v-btn
          color="default"
          @click="clean"
          class="text-none elevation-0 mr-2">
          <v-icon
            left
            dark>
            fa-broom
          </v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <data-table ref="adminExpositors" :url="url" :params="params" :headers="headers">
          <template v-slot:item.isActive="{ item }">
            <v-switch class="ma-0"
                      v-model="item.isActive"
                      dense
                      hide-details
                      @click="onDisableUser(item)"
            ></v-switch>
          </template>
        </data-table>
      </div>
    </div>
    <conference-admin-user-modal ref="conferenceAdminUserModal" :companies="companies" :fairId="fairId" @onClose="onClose" @getCompanies="getCompanies"/>
    <confirm-dialog
      :title="'Confirmación'"
      :description="`¿Deseas ${(Boolean(selectedUser.isActive) ? 'activar' : 'desactivar')}  el usuario?`"
      @onClose="closeDisableExpositorUserModal()"
      @onConfirm="disableExpositorUser()"
      :is-active="isConfirmDisableUserActive"
    />
  </div>
</template>
<script>
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { FAIRS_URL, CONFERENCE_ADMIN_USER_URL } from '@/constants/ServicesConstants'
import headers from '@/views/private/conferences/components/conference-user-manager/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import ConferenceAdminUserModal
from '@/views/private/conferences/components/conference-user-manager/components/ConferenceAdminUserModal.vue'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    ConfirmDialog,
    DataTable,
    ConferenceAdminUserModal
  },
  props: {
    fairId: {
      default: null,
      type: Number
    }
  },
  data () {
    return {
      headers,
      url: CONFERENCE_ADMIN_USER_URL + this.fairId,
      params: {
        filterName: '',
        filterEmail: '',
        filterCompany: ''
      },
      companies: [],
      fair: null,
      selectedUser: {},
      isConfirmDisableUserActive: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    clean () {
      this.params.filterName = null
      this.params.filterEmail = null
      this.params.filterCompany = null
      this.search()
    },
    onClose () {
      this.$refs.adminExpositors.getDataFromApi()
    },
    openConferenceAdminUserModal (item) {
      this.$refs.conferenceAdminUserModal.open(item)
    },
    search () {
      this.$refs.adminExpositors.getDataFromApi()
    },
    onDisableUser (item) {
      this.selectedUser = item
      this.isConfirmDisableUserActive = true
    },
    disableExpositorUser () {
      this.isConfirmDisableUserActive = false
      this.update(CONFERENCE_ADMIN_USER_URL + 'enable', this.selectedUser).then(() => {
        this.$refs.adminExpositors.getDataFromApi()
      })
    },
    closeDisableExpositorUserModal () {
      this.isConfirmDisableUserActive = false
      this.selectedUser.isActive = !this.selectedUser.isActive
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.fairId)
      this.fair = response.data
    },
    async getCompanies () {
      const response = await this.get(FAIRS_URL + '/' + this.fairId +
        '/companies', { params: { isConferenceAdmin: true } })
      this.companies = response.data
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      await this.getCompanies()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'ConferenceUserManager'
}
</script>
