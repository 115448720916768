export const BOOKING_CONFERENCE_STATUS = {
  onHold: {
    code: 'onHold',
    description: 'En espera',
    color: '#F29100'
  },
  successful: {
    code: 'successful',
    description: 'Aprobado',
    color: '#13e23f'
  },
  rejected: {
    code: 'rejected',
    description: 'Rechazada',
    color: '#F44336'
  }
}
export default BOOKING_CONFERENCE_STATUS
