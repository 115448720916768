<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="headline"> {{ isEdit ? 'Editar' : 'Crear' }} charla</span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12" v-if="!conferenceBookingTime">
                <p> Seleccione un salón en el horario deseado, tendrás 10 minutos para completar tu reserva.</p>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      @click="onSelectTime"
                      v-on="on"
                      class="white--text text-none elevation-0">
                      <v-icon
                        left
                        dark>
                        fa-calendar
                      </v-icon>
                      Seleccionar espacio
                    </v-btn>
                  </template>
                  <span> Seleccione un salón en el horario deseado </span>
                </v-tooltip>
              </div>
            </div>
            <div class="row" v-if="conferenceBookingTime">
              <div class="col-12 pb-0">
                <h4 class="mb-4 text--primary">
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    mdi-calendar
                  </v-icon>
                 <strong>Espacio seleccionado:</strong>
                </h4>
              </div>
              <div class="col-3 py-0 pl-8">
                <p class="text--primary mt-1"><strong>Fecha:</strong> {{ formatDate(conferenceBookingTime.availableDate) }}</p>
              </div>
              <div class="col-3 py-0 pl-8">
                <p class="text--primary mt-1"><strong>Hora:</strong> {{ conferenceBookingTime.time }}</p>
              </div>
              <div class="col-3 py-0 pl-8">
                <p class="text--primary mt-1"><strong>Salón:</strong> {{ conferenceBookingTime.conferenceRoom }}</p>
              </div>
              <div class="col-3 py-0 text-right">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      @click="onSelectTime"
                      v-on="on"
                      class="white--text text-none elevation-0 mr-2 ml-3">
                      <v-icon
                        left
                        dark>
                        fa-calendar-times
                      </v-icon>
                      Editar espacio
                    </v-btn>
                  </template>
                  <span> Seleccione un salón en el horario deseado </span>
                </v-tooltip>
              </div>
              <div class="col-12 pb-0">
                <h4 class="mb-4 text--primary">
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    mdi-bullhorn
                  </v-icon>
                  <strong>Información de la charla:</strong>
                </h4>
              </div>
              <div class="col-6 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="nombre de la charla"
                  vid="name"
                  rules="required|max:60"
                >
                  <v-text-field
                    label="Nombre de la charla*"
                    v-model="model.conferenceName"
                    outlined
                    dense
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-6 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="nombre del conferencista"
                  vid="speaker"
                  rules="required|max:60"
                >
                  <v-text-field
                    label="Nombre del conferencista*"
                    v-model="model.speaker"
                    outlined
                    dense
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="palabras clave"
                  vid="keywords"
                  rules=""
                >
                    <v-select
                      v-model="model.keywords"
                      :items="keywords"
                      :multiple="true"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      label="Palabras clave"
                      :error-messages="errors"
                    />
                </validation-provider>
              </div>
              <div class="col-12 pb-0">
                <h4 class="mb-4 text--primary">
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    mdi-information
                  </v-icon>
                  <strong>Resumen:</strong>
                </h4>
              </div>
              <div class="col-12 py-0 pl-4">
                <p class="justify">
                  Si aún no tienes los datos de la charla te recomendamos escribir en los campos las palabras <strong>por
                  definir</strong> para que puedas solicitar el espacio. Siempre podrás volver sobre la charla para
                  editar la información. Recuerda que una vez aprobada la charla, la información ingresada será visible
                  al público.
                </p>
              </div>
              <div class="col-12 pb-0">
               <validation-provider
                  v-slot="{ errors }"
                  name="resumen"
                  vid="conferenceName"
                  rules="required|max:300"
                >
                  <v-textarea
                    counter
                    outlined
                    clearable
                    type="text"
                    label="Resumen*"
                    :error-messages="errors"
                    clear-icon="mdi-close-circle"
                    v-model="model.summary"
                  />
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="conferenceBookingTime && !isEdit"
            color="primary"
            text
            @click="save"
          >
            Solicitar espacio
          </v-btn>
          <v-btn
            v-if="conferenceBookingTime && isEdit"
            color="primary"
            text
            @click="save"
          >
            Guardar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <conference-booking-time-modal ref="ConferenceBookingTimeModal"/>
  </v-row>
</template>
<script>

import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { CONFERENCE_BOOKINGS_URL, CONFERENCE_KEYWORDS_URL } from '@/constants/ServicesConstants'
import ConferenceBookingTimeModal from '@/views/private/conference-bookings/components/ConferenceBookingTimeModal'
import moment from 'moment'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'ConferenceBookingModal',
  components: { ConferenceBookingTimeModal },
  data () {
    return {
      conferenceBooking: null,
      conferenceBookingTime: null,
      fairId: null,
      isActive: false,
      isEdit: false,
      keywords: [],
      model: {
        conferenceName: null,
        speaker: null,
        keywords: null,
        summary: null,
        conferenceScheduleTimeId: null,
        isAdmin: false
      }
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin],
  methods: {
    onSelectTime () {
      this.$refs.ConferenceBookingTimeModal.open(this.fairId).then((value) => {
        this.conferenceBookingTime = value
      }).catch((error) => {
        throw error
      })
    },
    clean () {
      this.model.conferenceName = null
      this.model.speaker = null
      this.model.keywords = null
      this.model.summary = null
      this.model.conferenceScheduleTimeId = null
      this.model.isAdmin = false
    },
    close () {
      this.isActive = false
      this.isEdit = false
      this.conferenceBookingTime = null
      this.$refs.form.reset()
      this.clean()
      this.rejectPromise()
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async getKeywords () {
      const response = await this.get(CONFERENCE_KEYWORDS_URL + '/all', { params: { fairId: this.fairId } })
      this.keywords = response.data
    },
    async open (conferenceBooking, fairId, isAdmin = false) {
      this.clean()
      this.isActive = true
      this.fairId = fairId
      this.conferenceBooking = { ...conferenceBooking }
      if (conferenceBooking) {
        this.isEdit = true
        this.conferenceBookingTime = {
          id: this.conferenceBooking.conference_schedule_time.id,
          availableDate: this.conferenceBooking.conference_schedule_time.conference_schedule.availableDate,
          time: this.conferenceBooking.conference_schedule_time.description,
          conferenceRoom: this.conferenceBooking.conference_schedule_time.conference_schedule.conference_room.name
        }
        this.model = {
          id: conferenceBooking.id,
          conferenceName: conferenceBooking.conferenceName,
          speaker: conferenceBooking.speaker,
          summary: conferenceBooking.summary,
          conferenceScheduleTimeId: this.conferenceBookingTime.id,
          isAdmin: isAdmin
        }
        this.model.keywords = this.conferenceBooking.conference_keywords.map((item) => item.id)
      }
      await this.getKeywords()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    save () {
      this.executeWithFormValidation(async () => {
        try {
          this.showLoader()
          this.model.conferenceScheduleTimeId = this.conferenceBookingTime.id
          this.isEdit ? await this.put(CONFERENCE_BOOKINGS_URL + '/' + this.model.id, this.model) : await this.post(CONFERENCE_BOOKINGS_URL, this.model)
          this.hideLoader()
          this.showSuccess('El espacio fue solicitado correctamente')
          this.isActive = false
          this.conferenceBookingTime = null
          this.$refs.form.reset()
          this.resolvePromise()
        } catch (error) {
          if (error.response && error.response.status === 422) {
            if (error.response.data && error.response.data.errors) {
              this.$refs.form.setErrors(error.response.data.errors)
            } else {
              this.$refs.form.setErrors(error.response.data)
            }
            this.invalidFields(error.response.data.error)
          } else {
            this.createFailNotify()
          }
          throw error
        } finally {
          this.hideLoader()
        }
      })
    }
  }
}
</script>
