const conferenceManagerHeaders = [
  {
    text: 'Nombre empresa',
    value: 'user.company.name',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Charla',
    value: 'conferenceName',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Conferencista',
    value: 'speaker',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Salon',
    value: 'conference_schedule_time.conference_schedule.conference_room.name',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Fecha',
    value: 'conference_schedule_time.conference_schedule.availableDate',
    sortable: false,
    width: '100px'
  },
  {
    text: 'Hora',
    value: 'conference_schedule_time.description',
    sortable: false,
    width: '100px'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '300px'
  },
  {
    text: 'Estado',
    value: 'status',
    sortable: false,
    width: '100px'
  },
  {
    text: 'Nuevo estado',
    value: 'newStatus',
    sortable: false,
    width: '160px'
  }
]
export default conferenceManagerHeaders
