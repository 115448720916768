const conferenceKeywordsHeaders = [
  {
    text: 'Nombre',
    value: 'name',
    sortable: false
  },
  {
    text: '¿Activa?',
    value: 'isActive',
    sortable: false,
    width: '100px'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '200px'
  }
]
export default conferenceKeywordsHeaders
