<template>
  <div v-if="fair">
    <div class="row">
      <div class="col-12 d-flex align-center justify-space-between">
        <h3>Configuración</h3>
      </div>
      <div class="col-12 col-xs-12 col-md-4 col-lg-3 d-flex justify-center">
        <div class="row">
          <div class="col-12 d-flex justify-center custom-col">
            <v-switch
              class="custom-switch"
              v-model="fair.isEmailNotificationActive"
              hide-details
              dense
              @click="onChangeEmailNotification()"
            ></v-switch>
          </div>
          <div class="col-12 d-flex justify-center custom-col">
            <h3>¿Envío de correos Activo?</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex align-center justify-space-between">
        <h3>Palabras claves</h3>
        <v-btn
          color="secondary"
          class="text--primary text-none elevation-0"
          @click="openConferenceKeywordModal()"
          dark>
          Crear palabra clave
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-text-field
          label="Buscar"
          v-model="conferenceKeywordParams.filter"
          outlined
          v-on:keyup.enter="searchConferenceKeywords"
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-end">
        <v-btn
          color="primary"
          @click="searchConferenceKeywords"
          class="white--text text-none elevation-0 mr-2">
          <v-icon
            left
            dark>
            fa-search
          </v-icon>
          Buscar
        </v-btn>
        <v-btn
          color="default"
          @click="cleanConferenceKeywords"
          class="text-none elevation-0">
          <v-icon
            left
            dark>
            fa-broom
          </v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <data-table ref="conferenceKeywords" :params="conferenceKeywordParams" :url="conferenceKeywordsUrl"
                    :headers="conferenceKeywordsHeaders">
          <template v-slot:item.isActive="{ item }">
            <v-switch class="ma-0" dense hide-details v-model="item.isActive"
                      @change="activateConferenceKeyword(item)"/>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="ma-2 elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="onEditKeyword(item)"
                  small
                  color="primary"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar salon</span>
            </v-tooltip>
            <v-tooltip v-if="!item.isUsed" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="ma-2 elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="onRemoveConferenceKeyword(item)"
                  small
                  color="error"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar palabra clave</span>
            </v-tooltip>
          </template>
        </data-table>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex align-center justify-space-between">
        <h3>Salones</h3>
        <v-btn
          color="secondary"
          class="text--primary text-none elevation-0"
          @click="openConferenceRoomModal()"
          dark>
          Crear salon
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <data-table ref="conferenceRooms" :params="conferenceRoomParams" :url="conferenceRoomsUrl" :headers="conferenceRoomsHeaders">
          <template v-slot:item.isActive="{ item }">
            <v-switch class="ma-0" dense hide-details v-model="item.isActive"
                      @change="activateConferenceRoom(item)"/>
          </template>
          <template v-slot:item.actions="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="ma-2 elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="onEditRoom(item)"
                  small
                  color="primary"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar salon</span>
            </v-tooltip>

            <v-tooltip v-if="!item.isReserved" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="ma-2 elevation-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="onRemoveConferenceRoom(item)"
                  small
                  color="error"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar salon</span>
            </v-tooltip>

          </template>
        </data-table>
      </div>
    </div>

    <conference-keyword-modal ref="conferenceKeywordModal"></conference-keyword-modal>
    <conference-room-modal ref="conferenceRoomModal"></conference-room-modal>

    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas eliminar la palabra clave?'"
      @onClose="isConfirmConferenceKeywordActive = false"
      @onConfirm="deleteConferenceKeyword()"
      :is-active="isConfirmConferenceKeywordActive"
    />

    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas eliminar el salon?'"
      @onClose="isConfirmConferenceRoomActive = false"
      @onConfirm="deleteConferenceRoom()"
      :is-active="isConfirmConferenceRoomActive"
    />

    <confirm-dialog
      :title="'Confirmación'"
      :description="`¿Deseas ${(Boolean(fair.isEmailNotificationActive) ? 'activar' : 'desactivar')}  el envío de correos?`"
      @onClose="closeChangeEmailNotificationModal"
      @onConfirm="changeEmailNotification"
      :is-active="isConfirmChangeEmailNotificationActive"
    />
  </div>
</template>
<script>
import conferenceKeywordsHeaders from './data/ConferenceKeywordsHeaders'
import conferenceRoomsHeaders from './data/ConferenceRoomsHeaders'
import { CONFERENCE_KEYWORDS_URL, CONFERENCE_ROOMS_URL, FAIRS_URL } from '@/constants/ServicesConstants'
import ConferenceKeywordModal from './components/ConferenceKeywordModal'
import DataTable from '../../../../../components/data-table/DataTable'
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog'
import crudServiceMixin from '../../../../../mixins/crudServiceMixin'
import formOperationsMixin from '../../../../../mixins/formOperationsMixin'
import notificationMixin from '../../../../../mixins/notificationMixin'
import loaderMixin from '../../../../../mixins/loaderMixin'
import ConferenceRoomModal from './components/ConferenceRoomModal'

export default {
  components: {
    ConfirmDialog,
    ConferenceKeywordModal,
    ConferenceRoomModal,
    DataTable
  },
  data () {
    return {
      conferenceKeywordsHeaders,
      conferenceRoomsHeaders,
      conferenceKeywordsUrl: CONFERENCE_KEYWORDS_URL,
      conferenceRoomsUrl: CONFERENCE_ROOMS_URL,
      isConfirmConferenceKeywordActive: false,
      isConfirmConferenceRoomActive: false,
      isConfirmChangeEmailNotificationActive: false,
      selectedConferenceKeyword: null,
      selectedConferenceRoom: null,
      conferenceKeywordParams: {
        fairId: this.fairId,
        filter: ''
      },
      conferenceRoomParams: {
        fairId: this.fairId
      },
      fair: null
    }
  },
  methods: {
    onEditRoom (item) {
      this.$refs.conferenceRoomModal.open(this.fairId, item).then(() => {
        this.$refs.conferenceRooms.getDataFromApi()
      }).catch(() => {
      })
    },
    onEditKeyword (item) {
      this.$refs.conferenceKeywordModal.open(this.fairId, item).then(() => {
        this.$refs.conferenceKeywords.getDataFromApi()
      }).catch(() => {
      })
    },
    onRemoveConferenceRoom (item) {
      this.isConfirmConferenceRoomActive = true
      this.selectedConferenceRoom = item
    },
    async onRemoveConferenceKeyword (item) {
      this.isConfirmConferenceKeywordActive = true
      this.selectedConferenceKeyword = item
    },
    activateConferenceKeyword (item) {
      this.update(
        CONFERENCE_KEYWORDS_URL + '/' + item.id + '/activate',
        item
      ).then(() => {
        this.$refs.conferenceKeywords.getDataFromApi()
      })
    },
    activateConferenceRoom (item) {
      this.update(
        CONFERENCE_ROOMS_URL + '/' + item.id + '/activate',
        item
      ).then(() => {
        this.$refs.conferenceRooms.getDataFromApi()
      })
    },
    deleteConferenceKeyword () {
      this.isConfirmConferenceKeywordActive = false
      this.remove(CONFERENCE_KEYWORDS_URL, this.selectedConferenceKeyword.id).then(() => {
        this.$refs.conferenceKeywords.getDataFromApi()
      })
    },
    deleteConferenceRoom () {
      this.isConfirmConferenceRoomActive = false
      this.remove(CONFERENCE_ROOMS_URL, this.selectedConferenceRoom.id).then(() => {
        this.$refs.conferenceRooms.getDataFromApi()
      })
    },
    openConferenceKeywordModal () {
      this.$refs.conferenceKeywordModal.open(this.fairId).then(() => {
        this.$refs.conferenceKeywords.getDataFromApi()
      }).catch(() => {
      })
    },
    openConferenceRoomModal () {
      this.$refs.conferenceRoomModal.open(this.fairId).then(() => {
        this.$refs.conferenceRooms.getDataFromApi()
      }).catch(() => {
      })
    },
    searchConferenceKeywords () {
      this.$refs.conferenceKeywords.getDataFromApi()
    },
    cleanConferenceKeywords () {
      this.conferenceKeywordParams.filter = null
      this.searchConferenceKeywords()
    },
    onChangeEmailNotification () {
      this.isConfirmChangeEmailNotificationActive = true
    },
    closeChangeEmailNotificationModal () {
      this.isConfirmChangeEmailNotificationActive = false
      this.fair.isEmailNotificationActive = !this.fair.isEmailNotificationActive
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.fairId)
      this.fair = response.data
    },
    changeEmailNotification () {
      this.put(FAIRS_URL + '/' + this.fair.id + '/change-email-notification-active')
        .then((res) => {
          this.isConfirmChangeEmailNotificationActive = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'ConferenceSettings',
  props: {
    fairId: {
      default: null,
      type: Number
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  }
}
</script>
