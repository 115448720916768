<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" max-width="500px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">Crear Palabra Clave
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  vid="name"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre"
                    required
                  />
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 py-0 pl-4">
                <v-switch hide-details v-model="model.isActive" label="¿Activa?"/>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveConferenceKeyword()">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="onClose">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import crudServiceMixin from '../../../../../../mixins/crudServiceMixin'
import formOperationsMixin from '../../../../../../mixins/formOperationsMixin'
import notificationMixin from '../../../../../../mixins/notificationMixin'
import loaderMixin from '../../../../../../mixins/loaderMixin'
import { CONFERENCE_KEYWORDS_URL } from '../../../../../../constants/ServicesConstants'

export default {
  data () {
    return {
      isEdit: false,
      isOpen: false,
      model: {
        name: null,
        isActive: true,
        fairId: null
      }
    }
  },
  methods: {
    async open (fairId, keyword) {
      if (keyword) {
        this.model.id = keyword.id
        this.model.name = keyword.name
        this.model.isActive = keyword.isActive
        this.model.fairId = keyword.fairId
        this.isEdit = true
      } else {
        this.model.fairId = fairId
        this.isEdit = false
      }
      this.isOpen = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    onClose () {
      this.isOpen = false
      this.onClear()
      this.rejectPromise()
    },
    onClear () {
      this.model.name = ''
      this.$refs.form.reset()
    },
    saveConferenceKeyword () {
      this.executeWithFormValidation(() => {
        if (this.isEdit) {
          this.update(CONFERENCE_KEYWORDS_URL + '/' + this.model.id, this.model).then(() => {
            this.onClear()
            this.isOpen = false
            this.resolvePromise()
          })
        } else {
          this.create(CONFERENCE_KEYWORDS_URL, this.model).then(() => {
            this.onClear()
            this.isOpen = false
            this.resolvePromise()
          })
        }
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'ConferenceKeywordModal'
}
</script>
