<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      width="70%"
    >
      <v-card>
        <v-card-title>
          <span class="headline"> Disponibilidad de espacios </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 pb-0">
                <p> Ingresa un rango de fechas y selecciona un espacio que esté disponible o dale doble clic a una misma
                  fecha si solo deseas ver la disponibilidad de ese día.</p>
                <date-picker ref="date" label="Fecha" v-model="date" :is-range="true" :allowed-dates="availableDates"/>
              </div>
              <div class="col-12">
                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    @click="search"
                    class="white--text text-none mr-2 elevation-0">
                    <v-icon
                      left
                      dark>
                      fa-search
                    </v-icon>
                    Buscar
                  </v-btn>
                  <v-btn
                    color="default"
                    @click="clean"
                    class="text-none elevation-0">
                    <v-icon
                      left
                      dark>
                      fa-broom
                    </v-icon>
                    Limpiar
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-center" v-if="loadingScheduleTimes">
              <v-progress-circular
                :size="50"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <template v-if="!loadingScheduleTimes">
              <div class="row">
                <div class="col-12" v-if="conferenceScheduleTimes">
                  <p>Haz click sobre el horario de la charla que desees seleccionar.</p>
                  <div class="d-flex justify-end mt-4">
                    <v-chip
                      color="green"
                      text-color="white"
                    >
                      Disponible
                    </v-chip>
                    <v-chip
                      color="yellow"
                      text-color="black"
                      class="ml-1"
                    >
                      En espera
                    </v-chip>
                    <v-chip
                      color="red"
                      text-color="white"
                      class="ml-1"
                    >
                      No disponible
                    </v-chip>
                  </div>
                </div>
                <div class="col-12" v-if="conferenceScheduleTimes && conferenceScheduleTimes.length==0">
                  <p class="text-center">No hay espacios disponibles en el rango de fecha seleccionado</p>
                </div>
              </div>
              <div class="row" v-for="(values, key) in conferenceScheduleTimes" :key="key">
                <div class="col-12 pl-4 py-0">
                  <h3 class="my-0">
                    <v-icon
                      dense
                      color="primary darken-2"
                    >
                      fa-calendar
                    </v-icon>
                    <span class="ml-2"> {{ formatDate(key) }} </span>
                  </h3>
                </div>
                <div class="col-12 pt-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left" width="300px">
                          <span class="custom-span">Salón</span>
                        </th>
                        <th class="text-left">
                          <span class="custom-span">Horarios</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="value in values"
                        :key="value.id"
                      >
                        <td>{{ value.conference_room.name }}</td>
                        <td>
                          <v-chip
                            v-for="time in value.times"
                            :key="time.id"
                            :color="defineTimeColor(time)"
                            :text-color="defineTimeTextColor(time)"
                            class="ml-1"
                            @click="showConfirmationMessage(time, value)"
                          >
                            {{ time.time.substring(0, 5) }}
                          </v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </template>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas seleccionar este espacio?'"
      @onClose="onClose"
      @onConfirm="selectTime"
      :is-active="isConfirmActive"
    />
  </v-row>
</template>
<script>

import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { CONFERENCE_SCHEDULE_TIMES_URL, CONFERENCE_SCHEDULES_URL } from '@/constants/ServicesConstants'
import DatePicker from '@/components/date-picker/DatePicker'
import moment from 'moment'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  name: 'ConferenceBookingTimeModal',
  components: { DatePicker, ConfirmDialog },
  async created () {
    await this.getAvailableDates()
  },
  data () {
    return {
      availableDates: [],
      date: null,
      loadingScheduleTimes: false,
      conferenceScheduleTimes: null,
      isActive: false,
      isConfirmActive: false,
      model: {
        fairId: null,
        date: null
      },
      time: null
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    clean () {
      this.date = null
      this.model.date = null
      this.conferenceScheduleTimes = null
      this.loadingScheduleTimes = false
    },
    close () {
      this.isActive = false
      this.conferenceScheduleTimes = null
      this.$refs.form.reset()
      this.time = null
      this.rejectPromise()
    },
    defineTimeColor (time) {
      if (time.isReserved) {
        return 'red'
      }
      switch (time.status) {
        case 'busy':
          return 'red'
        case 'onHold':
          return 'yellow'
        default:
          return 'green'
      }
    },
    defineTimeTextColor (time) {
      if (time.isReserved) {
        return 'white'
      }
      switch (time) {
        case 'busy':
          return 'white'
        case 'onHold':
          return 'black'
        default:
          return 'white'
      }
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async getAvailableDates () {
      const response = await this.get(CONFERENCE_SCHEDULES_URL + '/available-dates', { params: this.model.fairId })
      this.availableDates = response.data
    },
    onClose () {
      this.isConfirmActive = false
      this.time = null
      this.conferenceScheduleTime = null
    },
    async preReserve () {
      try {
        await this.get(CONFERENCE_SCHEDULE_TIMES_URL + '/' + this.time.id)
        this.isActive = false
        this.conferenceScheduleTimes = null
        this.$refs.form.reset()
        this.time.availableDate = this.conferenceScheduleTime.availableDate
        this.time.conferenceRoom = this.conferenceScheduleTime.conference_room.name
        this.resolvePromise(this.time)
      } catch (error) {
        if (error.response && error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            this.$refs.form.setErrors(error.response.data.errors)
          } else {
            this.$refs.form.setErrors(error.response.data)
          }
          this.invalidFields(error.response.data.error)
        } else {
          this.createFailNotify()
        }
        throw error
      } finally {
        this.hideLoader()
      }
    },
    async search () {
      try {
        this.loadingScheduleTimes = true
        const response = await this.get(CONFERENCE_SCHEDULES_URL, { params: this.model })
        this.conferenceScheduleTimes = response.data
        this.loadingScheduleTimes = false
      } catch {
        this.loadingScheduleTimes = false
      }
    },
    selectTime () {
      this.isConfirmActive = false
      this.preReserve()
    },
    async open (fairId) {
      this.clean()
      this.isActive = true
      this.model.fairId = fairId
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    showConfirmationMessage (time, conferenceScheduleTime) {
      if (time.status === 'available' && !time.isReserved) {
        this.time = time
        this.conferenceScheduleTime = conferenceScheduleTime
        this.isConfirmActive = true
      }
    }
  },
  watch: {
    date (val) {
      if (val && val.from && val.to) {
        this.model.date = []
        this.model.date[0] = val.from
        this.model.date[1] = val.to
      }
    }
  }
}
</script>
<style scoped>
.custom-span {
  color: black !important;
}
</style>
