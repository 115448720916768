export const CONFERENCE_BOOKING_STATUS = {
  onHold: {
    code: 'onHold',
    description: 'En espera',
    color: '#222c33'
  },
  successful: {
    code: 'successful',
    description: 'Aprobado',
    color: '#13e23f'
  },
  rejected: {
    code: 'rejected',
    description: 'Rechazada',
    color: '#F44336'
  }
}
export default CONFERENCE_BOOKING_STATUS
