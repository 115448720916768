<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="900px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Nueva Compañia Administrador Charlas Técnicas
          </span>
        </v-card-title>
        <v-card-text class="mt-3">
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="companyIdentification"
                    :name="rutLabel"
                    rules="required|nit"
                  >
                    <v-text-field
                      v-model="model.companyIdentification"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      :label="rutLabel +  ' (Sin código de verificación)'"
                      class="px-1"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="companyBusinessName"
                    name="Razón Social"
                    rules="required|max:80"
                  >
                    <v-text-field
                      v-model="model.companyBusinessName"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Razón social"
                      class="px-1"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div> <div class="col-6 custom-col">
                <validation-provider
                  v-slot="{ errors }"
                  vid="companyName"
                  name="Nombre comercial"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.companyName"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre comercial"
                    class="px-1"
                    required
                  ></v-text-field>
                </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    vid="companyEmail"
                    v-slot="{ errors }"
                    name="Correo corporativo"
                    rules="email"
                  >
                    <v-text-field
                      v-model="model.companyEmail"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Correo corporativo"
                      class="px-1"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirmar Email"
                    vid="email_confirmation"
                    rules="required|email|max:80|confirmed:companyEmail"
                  >
                    <v-text-field
                      v-model="model.email_confirmation"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Confirmar Email"
                      class="px-1"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="companyPhone"
                    name="Teléfono de la empresa"
                    :rules="{required: true,phone: true}"
                  >
                    <vue-tel-input-vuetify
                      label="Teléfono de la empresa"
                      :error-messages="errors"
                      class="flex-wrap px-1"
                      mode="international"
                      :inputOptions="{showDialCode:false}"
                      required
                      outlined
                      dense
                      :preferred-countries="['CO', 'US']"
                      v-model="model.companyPhone"
                    />
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Extensión"
                      vid="companyPhoneExtension"
                      rules="integer"
                    >
                      <v-text-field
                        v-model="model.companyPhoneExtension"
                        type="text"
                        outlined
                        dense
                        :error-messages="errors"
                        label="Extensión"
                        required
                      ></v-text-field>
                    </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Dirección"
                    vid="companyAddress"
                    rules="required|max:80"
                  >
                    <v-text-field
                      v-model="model.companyAddress"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Dirección"
                      class="px-1"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Página Web"
                    vid="companyWebSite"
                    rules="max:80"
                  >
                    <v-text-field
                      v-model="model.companyWebSite"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Página Web"
                      class="px-1"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider vid="companyCountryId" v-slot="{ errors }" name="País" rules="required">
                    <v-select
                      v-model="model.companyCountryId"
                      :error-messages="errors"
                      :items="countries"
                      @change="onChangeCountry"
                      outlined
                      dense
                      item-text="name"
                      item-value="id"
                      label="País"
                      class="px-1"
                    />
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider vid="companyDepartmentId" v-slot="{ errors }" name="Departamento" rules="required">
                    <v-select
                      v-model="model.companyDepartmentId"
                      :error-messages="errors"
                      :items="departments"
                      @change="onChangeDepartment"
                      outlined
                      dense
                      item-text="name"
                      item-value="id"
                      label="Departamento"
                      class="px-1"
                    />
                  </validation-provider>
                </div>
                <div class="col-6 custom-col">
                  <validation-provider vid="companyCityId" v-slot="{ errors }" name="Ciudad" rules="required">
                    <v-select
                      v-model="model.companyCityId"
                      :error-messages="errors"
                      :items="cities"
                      outlined
                      dense
                      item-text="name"
                      item-value="id"
                      label="Ciudad"
                      class="px-1"
                    ></v-select>
                  </validation-provider>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { CITIES_URL, CONFERENCE_ADMIN_USER_URL, COUNTRIES_URL, DEPARTMENTS_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'ConferenceAdminCompanyModal',
  props: {
    fairId: {
      type: Number
    },
    companies: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      countries: [],
      departments: [],
      cities: [],
      isActive: false,
      rutLabel: process.env.VUE_APP_RUT_LABEL,
      model: {
        companyIdentification: '',
        companyBusinessName: '',
        companyEmail: '',
        companyPhone: '',
        companyPhoneExtension: '',
        companyAddress: '',
        companyWebSite: '',
        companyCountryId: '',
        companyCountryName: '',
        companyDepartmentId: '',
        companyDepartmentName: '',
        companyCityId: '',
        companyCityName: ''
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        companyIdentification: '',
        companyBusinessName: '',
        companyEmail: '',
        companyPhone: '',
        companyPhoneExtension: '',
        companyAddress: '',
        companyWebSite: '',
        companyCountryId: '',
        companyCountryName: '',
        companyDepartmentId: '',
        companyDepartmentName: '',
        companyCityId: '',
        companyCityName: ''
      }
    },
    async getCountries () {
      this.departments = []
      this.cities = []
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async onChangeCountry () {
      try {
        this.showLoader()
        await this.getDepartments()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async onChangeDepartment () {
      try {
        this.showLoader()
        await this.getCities()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getDepartments (countryId) {
      this.cities = []
      this.departments = []
      const response = await this.getById(DEPARTMENTS_URL, this.model.companyCountryId || countryId)
      this.departments = response.data
    },
    async getCities (departmentId) {
      this.cities = []
      const response = await this.getById(CITIES_URL, this.model.companyDepartmentId || departmentId)
      this.cities = response.data
    },
    open () {
      this.isActive = true
    },
    save () {
      this.executeWithFormValidation(() => {
        const formData = this.getFormData()
        this.create(CONFERENCE_ADMIN_USER_URL + this.fairId + '/companies', formData).then(() => {
          this.close()
          this.$emit('onClose')
        })
      })
    }
  },
  created () {
    this.getCountries()
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
<style scoped>
  .custom-col{
    padding: 0 !important;
  }
</style>
